import React from 'react';
import { Container } from 'react-bootstrap';



const Athletics = () => {
    return (
        <>
        <Container style={{ marginTop: '1rem' }}>
        <h1>Power of 10 Data Visualisation</h1>
        <hr />
        <p>
          All data comes from <a href="https://www.thepowerof10.info/">power of 10</a>. I've kept the charts as simple as possible.
           This page exists solely to plot results. Wind assisted results have not been included in the plots below.
        </p>
        <p>
          The plots below show the season's best time for each athlete in each event. These plots are interactive, so they are best viewed on a desktop, or a tablet.
        </p>
        <Container fluid> 
        <iframe 
        src="100m_Women.html" 
        title="100m Women" 
        style={{ width: '100%', height: '700px', border: 'none' }}
      />
      </Container>
      <Container fluid> 
        <iframe 
        src="100m_Men.html" 
        title="100m Men" 
        style={{ width: '100%', height: '700px', border: 'none' }}
      />
      </Container>
     
      <Container fluid> 
        <iframe 
        src="200m_Women.html" 
        title="200m Women" 
        style={{ width: '100%', height: '700px', border: 'none' }}
      />
      </Container>
      <Container fluid> 
        <iframe 
        src="200m_Men.html" 
        title="200m Men" 
        style={{ width: '100%', height: '700px', border: 'none' }}
      />
      </Container>

      <Container fluid> 
        <iframe 
        src="400m_Women.html" 
        title="400m Women" 
        style={{ width: '100%', height: '700px', border: 'none' }}
      />
      </Container>
      <Container fluid> 
        <iframe 
        src="400m_Men.html" 
        title="400m Men" 
        style={{ width: '100%', height: '700px', border: 'none' }}
      />
      </Container>


        </Container>
        </>
        );
    }   
    

export default Athletics;