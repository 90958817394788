import React from 'react';
import { Nav, Navbar as BootstrapNavbar, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const CustomNavbar = () => {
  return (
    <BootstrapNavbar expand="lg" className="bg-white py-3">
      <Container>
        <BootstrapNavbar.Brand as={NavLink} to="/" className="fw-bold text-primary">
          inorite
        </BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <NavLink to="/about" className="nav-link px-3" activeClassName="fw-bold text-primary">
              What we do
            </NavLink>
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};

export default CustomNavbar;