// Style and Framework Imports
import './App.css';

// React and Router Imports
import React from 'react';
import {Routes, Route } from "react-router-dom";


// // Custom Hook Imports
// import usePageTracking from './usePageTracking'; 

// Pages
import Home from './pages/Home';
import UKElectricityGrid from './pages/UKElectricityGrid';
import HousePrices from './pages/HousePrices';
import Athletics from './pages/Athletics';
import GasLeaks from './pages/GasLeaks';
import About from './pages/About';
import Wind from './pages/Wind';
import NestedHeatTransfer  from './pages/heat'







// Components

import CustomNavbar from "./components/Navbar";
import Footer from "./components/Footer";



function App() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>

    <CustomNavbar />
    <div style={{ flex: 1 }}>
      <Routes>
        
        <Route path="/" element={<Home />} />
        <Route path="/uk-electricity-grid" element={<UKElectricityGrid />} />
        <Route path="/gas-leaks-rg8" element={<GasLeaks />} />
        <Route path="/house-prices" element={<HousePrices />} />
        <Route path="/athletics" element={<Athletics />} />
        <Route path="/about" element={<About />} />
        <Route path="/wind" element={<Wind />} />
        <Route path="/nested-heat-transfer" element={<NestedHeatTransfer />} />


        <Route path="*" element={<p>Page not found</p>} />
      </Routes>

    </div>
    <Footer />
  </div>
  );
}

export default App;
