import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Boxes from '../components/Boxes';

const HeroContent = () => (
  <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '80vh' }}>
    <Row className="text-center">
      <Col xs={12} className="mb-4">
        <h1 className="display-4 fw-bold mb-3">
          find <span className="text-primary">the meaning</span> in your <span className="text-success">data</span>
        </h1>
        <p className="lead mb-4">
          data gurus for hire
        </p>
      </Col>
    </Row>
  </Container>
);

const TallyContent = () => (
  <iframe 
    data-tally-src="https://tally.so/embed/nrd77o?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1" 
    loading="lazy" 
    width="100%" 
    height="276" 
    frameBorder="0" 
    marginHeight="0" 
    marginWidth="0" 
    title="Contact form"
  ></iframe>
);

function Home() {
  useEffect(() => {
    // Load Tally script
    const script = document.createElement('script');
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      // Load all embeds on the page
      window.Tally.loadEmbeds();
    };

    // Cleanup
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <Container style={{ marginTop: '1rem' }}>
        <HeroContent />
        <Boxes />
        <Row className="mt-5 justify-content-center">
          <Col xs={12} md={8} lg={6}>
            <Container className="text-center">
              <h2>Got a Data Challenge? Let's chat!</h2>
              <TallyContent />
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Home;