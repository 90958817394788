import React from 'react';
import './Boxes.css';

const Boxes = () => (

    <div className="d-flex flex-column flex-md-row">
      <div className="box box1">
        <h3>State Analysis</h3>
        <p>Comprehensive evaluation of current systems and processes to identify areas for improvement and optimisation.</p>
      </div>
      <div className="box box2">
        <h3>Data Engineering</h3>
        <p>Design and implementation of robust data pipelines to collect, process, and store large-scale datasets efficiently.</p>
      </div>
      <div className="box box3">
        <h3>Data Science</h3>
        <p>Advanced analytics and machine learning techniques to extract valuable insights and drive data-driven decision making.</p>
      </div>
      <div className="box box4">
        <h3>ML Operations</h3>
        <p>Streamlined deployment, monitoring, and maintenance of machine learning models in production environments.</p>
      </div>
    </div>

);

export default Boxes;