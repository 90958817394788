import React, { useState, useEffect, useRef, useCallback } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Card, Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Pause, Play, RefreshCw, Flame } from 'lucide-react';

const NestedHeatTransfer = () => {
    const [innerTemp, setInnerTemp] = useState(35);
    const [outerTemp, setOuterTemp] = useState(15);
    const [ambientTemp, setAmbientTemp] = useState(10);
    const [initialInnerTemp, setInitialInnerTemp] = useState(35);
    const [initialOuterTemp, setInitialOuterTemp] = useState(15);
    const [k1, setK1] = useState(1);
    const [k2, setK2] = useState(0.5);
    const [thermostatTemp, setThermostatTemp] = useState(17);
    const [maxHeatingPower, setMaxHeatingPower] = useState(50);
    
    const [useProportional, setUseProportional] = useState(false);
    const [proportionalGain, setProportionalGain] = useState(5);
    const [currentHeatingPower, setCurrentHeatingPower] = useState(0);
    
    const [isHeating, setIsHeating] = useState(false);
    const [data, setData] = useState([]);
    const [isRunning, setIsRunning] = useState(false);
    const [time, setTime] = useState(0);
    const lastUpdateRef = useRef(0);
    const animationFrameRef = useRef();
  
    const FIXED_DT = 0.016;
    const MAX_POINTS = 300;

    const getColor = (temp, minTemp, maxTemp) => {
        const normalizedTemp = Math.max(0, Math.min(1, (temp - minTemp) / (maxTemp - minTemp)));
        const red = Math.floor(255 * normalizedTemp);
        const blue = Math.floor(255 * (1 - normalizedTemp));
        return `rgb(${red}, 0, ${blue})`;
    };

    const calculateHeatingPower = useCallback((currentTemp) => {
        if (currentTemp >= thermostatTemp) {
            return 0;
        }
        
        if (useProportional) {
            const error = thermostatTemp - currentTemp;
            const power = proportionalGain * error;
            return Math.min(Math.max(0, power), maxHeatingPower);
        } else {
            return maxHeatingPower;
        }
    }, [thermostatTemp, useProportional, proportionalGain, maxHeatingPower]);

    const calculateTemperatures = useCallback((Ti, To, Ta, k1, k2, dt) => {
        const heating = calculateHeatingPower(To);
        setCurrentHeatingPower(heating);
        setIsHeating(heating > 0);
        
        const dTi = (-k1 * (Ti - To) + heating) * dt;
        const dTo = (k1 * (Ti - To) - k2 * (To - Ta)) * dt;
        
        return {
            newInnerTemp: Ti + dTi,
            newOuterTemp: To + dTo
        };
    }, [calculateHeatingPower]);

    const resetSimulation = () => {
        setInnerTemp(initialInnerTemp);
        setOuterTemp(initialOuterTemp);
        setTime(0);
        setCurrentHeatingPower(0);
        setData([{
            time: 0,
            innerTemp: initialInnerTemp,
            outerTemp: initialOuterTemp,
            ambientTemp: ambientTemp,
            heatingActive: false
        }]);
        setIsRunning(false);
        setIsHeating(false);
        lastUpdateRef.current = 0;
        if (animationFrameRef.current) {
            cancelAnimationFrame(animationFrameRef.current);
        }
    };

    const toggleSimulation = () => {
        setIsRunning(!isRunning);
    };
  
    useEffect(() => {
        let accumulatedTime = 0;

        const animate = (timestamp) => {
            if (!lastUpdateRef.current) {
                lastUpdateRef.current = timestamp;
            }

            const deltaTime = (timestamp - lastUpdateRef.current) / 1000;
            accumulatedTime += deltaTime;

            while (accumulatedTime >= FIXED_DT) {
                const newTime = time + FIXED_DT;
                const { newInnerTemp, newOuterTemp } = calculateTemperatures(
                    innerTemp,
                    outerTemp,
                    ambientTemp,
                    k1,
                    k2,
                    FIXED_DT
                );

                setTime(newTime);
                setInnerTemp(newInnerTemp);
                setOuterTemp(newOuterTemp);
                
                setData(prevData => {
                    const newData = [...prevData, {
                        time: newTime.toFixed(1),
                        innerTemp: newInnerTemp.toFixed(2),
                        outerTemp: newOuterTemp.toFixed(2),
                        ambientTemp: ambientTemp,
                        heatingPower: currentHeatingPower.toFixed(1)
                    }];
                    
                    if (newData.length > MAX_POINTS) {
                        return newData.slice(-MAX_POINTS);
                    }
                    return newData;
                });

                accumulatedTime -= FIXED_DT;
            }

            lastUpdateRef.current = timestamp;
            animationFrameRef.current = requestAnimationFrame(animate);
        };

        if (isRunning) {
            animationFrameRef.current = requestAnimationFrame(animate);
        }

        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, [
        isRunning,
        innerTemp,
        outerTemp,
        ambientTemp,
        k1,
        k2,
        time,
        calculateTemperatures,
        currentHeatingPower
    ]);

    return (
        <Container fluid className="p-4">
            <Card>
                <Card.Header>
                    <Card.Title>Heat Transfer Simulation with PID-like Control</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row className="mb-4">
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Initial Inner Temp (°C)</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={initialInnerTemp}
                                    onChange={(e) => {
                                        const value = Number(e.target.value);
                                        if (!isNaN(value)) {
                                            setInitialInnerTemp(value);
                                            resetSimulation();
                                        }
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Initial Outer Temp (°C)</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={initialOuterTemp}
                                    onChange={(e) => {
                                        const value = Number(e.target.value);
                                        if (!isNaN(value)) {
                                            setInitialOuterTemp(value);
                                            resetSimulation();
                                        }
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Ambient Temp (°C)</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={ambientTemp}
                                    onChange={(e) => {
                                        const value = Number(e.target.value);
                                        if (!isNaN(value)) {
                                            setAmbientTemp(value);
                                        }
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Inner Transfer Coefficient (k₁)</Form.Label>
                                <Form.Control
                                    type="number"
                                    step="0.01"
                                    min="0"
                                    value={k1}
                                    onChange={(e) => {
                                        const value = Number(e.target.value);
                                        if (!isNaN(value) && value >= 0) {
                                            setK1(value);
                                        }
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Outer Transfer Coefficient (k₂)</Form.Label>
                                <Form.Control
                                    type="number"
                                    step="0.01"
                                    min="0"
                                    value={k2}
                                    onChange={(e) => {
                                        const value = Number(e.target.value);
                                        if (!isNaN(value) && value >= 0) {
                                            setK2(value);
                                        }
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Thermostat Setting (°C)</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={thermostatTemp}
                                    onChange={(e) => {
                                        const value = Number(e.target.value);
                                        if (!isNaN(value)) {
                                            setThermostatTemp(value);
                                        }
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Max Heating Power</Form.Label>
                                <Form.Control
                                    type="number"
                                    min="0"
                                    value={maxHeatingPower}
                                    onChange={(e) => {
                                        const value = Number(e.target.value);
                                        if (!isNaN(value) && value >= 0) {
                                            setMaxHeatingPower(value);
                                        }
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Control Mode</Form.Label>
                                <Form.Check
                                    type="switch"
                                    label={useProportional ? 'Proportional' : 'On/Off'}
                                    checked={useProportional}
                                    onChange={(e) => setUseProportional(e.target.checked)}
                                />
                            </Form.Group>
                        </Col>
                        {useProportional && (
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Proportional Gain</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="0.1"
                                        min="0"
                                        value={proportionalGain}
                                        onChange={(e) => {
                                            const value = Number(e.target.value);
                                            if (!isNaN(value) && value >= 0) {
                                                setProportionalGain(value);
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        )}
                    </Row>

                    <div className="d-flex gap-2 mb-4">
                        <Button variant="primary" onClick={toggleSimulation}>
                            {isRunning ? <Pause className="me-2" /> : <Play className="me-2" />}
                            {isRunning ? 'Pause' : 'Start'}
                        </Button>
                        <Button variant="outline-secondary" onClick={resetSimulation}>
                            <RefreshCw className="me-2" />
                            Reset
                        </Button>
                    </div>

                   
                    <Row>
                    <Col md={4}>
    <div className="d-flex flex-column align-items-center position-relative">
        {/* Temperature info moved to the top and centered */}
        <div className="text-center mb-3" style={{ fontSize: '0.875rem' }}>
            Ambient: {ambientTemp}°C
            <br />
            Thermostat: {thermostatTemp}°C
            <br />
            Heating: {currentHeatingPower.toFixed(1)} units
        </div>
        
        {/* Main visualization container */}
        <div className="d-flex justify-content-center">
            <div
                className="rounded d-flex align-items-center justify-content-center"
                style={{
                    width: "200px",
                    height: "200px",
                    backgroundColor: getColor(outerTemp, ambientTemp, Math.max(initialInnerTemp, initialOuterTemp)),
                    boxShadow: `0 0 20px ${getColor(outerTemp, ambientTemp, Math.max(initialInnerTemp, initialOuterTemp))}`,
                    transition: 'all 0.2s'
                }}
            >
                <div
                    className="rounded position-relative"
                    style={{
                        width: "100px",
                        height: "100px",
                        backgroundColor: getColor(innerTemp, ambientTemp, Math.max(initialInnerTemp, initialOuterTemp)),
                        boxShadow: `0 0 20px ${getColor(innerTemp, ambientTemp, Math.max(initialInnerTemp, initialOuterTemp))}`,
                        transition: 'all 0.2s'
                    }}
                >
                    {isHeating && (
                        <div className="position-absolute top-50 start-50 translate-middle">
                            <Flame 
                                className="text-danger" 
                                size={32} 
                                style={{
                                    opacity: currentHeatingPower / maxHeatingPower,
                                    animation: 'pulse 1s infinite'
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
</Col>
                    <Col md={8}>
                    <LineChart
                            width={800}
                            height={400}
                            data={data}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        ><CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                            dataKey="time" 
                            label={{ value: 'Time (seconds)', position: 'bottom' }}
                        />
                        <YAxis 
                            label={{ value: 'Temperature (°C)', angle: -90, position: 'left' }}
                            domain={['auto', 'auto']}
                        />
                        <Tooltip />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="innerTemp"
                            stroke="#ff0000"
                            name="Inner Temperature"
                            dot={false}
                            isAnimationActive={false}
                        />
                        <Line
                            type="monotone"
                            dataKey="outerTemp"
                            stroke="#0000ff"
                            name="Outer Temperature"
                            dot={false}
                            isAnimationActive={false}
                        />
                        <Line
                            type="monotone"
                            dataKey="ambientTemp"
                            stroke="#888888"
                            name="Ambient Temperature"
                            dot={false}
                            isAnimationActive={false}
                        />
                        <Line
                            type="monotone"
                            dataKey="heatingPower"
                            stroke="#ff8800"
                            name="Heating Power"
                            dot={false}
                            isAnimationActive={false}
                        />
                    </LineChart>
                    </Col>

                    </Row>
            </Card.Body>
        </Card>
    </Container>
);
};

export default NestedHeatTransfer;