import React from 'react';
import { Container, Image } from 'react-bootstrap';



const GasLeaksReport = () => {
    return (
        <Container style={{ marginTop: '1rem' }}>
            <h1>Emission Omissions: The Scale of Gas Leaks and how EPCs ignore them</h1>
            <hr />
            
            <section>
                <h2>Introduction</h2>
                <p>As a former resident of Goring, I became interested/obsessed in investigating the environmental impact of gas leaks in our local area. What began as a local inquiry has revealed insights with significant implications for national energy policy and climate change efforts.</p>
            </section>

            <section>
                <h2>The Scale of Gas Leaks: RG8's Data</h2>
                <p>After submitting a request for information to Southern Gas Networks (SGN) under the Environmental Information Regulations 2004 and some back-and-forth with SGN, I received data on gas leaks in the RG8 postcode area, which covers Streatley, Goring, Pangbourne, and Whitchurch-on-Thames:</p>
                <ul>
                    <li>Over a two-year period (11 August 2022 to 11 August 2024), there were approximately 226 reported gas escapes in our area.</li>
                    <li>This number includes both internal and external reported gas escapes.</li>
                    <li>On average, this equates to about 9.4 reported gas escapes per month, or roughly one every three days.</li>
                </ul>
                <p> An accompanying letter can be found <a href="https://www.whatdotheyknow.com/request/volume_of_gas_leak/response/2751576/attach/2/SGN%20Response%20Letter%20v04.09.2024.pdf?cookie_passthrough=1">here</a>.</p>
                <p> The data can be found <a href="https://www.whatdotheyknow.com/request/volume_of_gas_leak/response/2773381/attach/2/Maximo%20Records.xlsx?cookie_passthrough=1">here</a>.</p>
            </section>

            <section>
                <h2>Understanding the Data: Maximo Records</h2>
                <p>Here's screenshot of the data received:</p>
                <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
                <Image src="sgn_leaks/Screenshot.webp" fluid alt="Screenshot of SGN data" />
                </Container>
                <p>SGN provided their data in the form of "Maximo Records", referring to IBM Maximo, an asset management software used by utilities. The records include:</p>
                <ul>
                    <li>Work Order: A unique identifier for each incident</li>
                    <li>Description: Typically "Attend Outside Gas Escape" or "Internal Escape uncontrolled"</li>
                    <li>Priority: Rated as either 3 or 10</li>
                    <li>Location: Street name and town (mostly READING, which covers our wider area)</li>
                    <li>Postcode: All within the RG8 area</li>
                    <li>Status: Usually "CLOSE" (closed) or "COMP" (completed)</li>
                    <li>Actual Start: Date and time the repair began</li>
                    <li>Job Type: Various codes (e.g., SR, AGE, MR) indicating different types of work</li>
                    <li>Asset: Likely an identifier for the specific piece of infrastructure involved</li>
                </ul>
                <p>I plotted the data on a map to visualise the spatial distribution of gas repairs in the area. Each repair is a response to a reported gas leak.</p>
            </section>

            <section>
            <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' , marginTop: '1rem', marginBottom: '1rem' }}>
            <Image src="sgn_leaks/data_analysis.webp" fluid alt="Screenshot of SGN data" style={{ width: '50%', height: 'auto' }} />
            </Container>
                <h2>Spatial Distribution and Its Implications</h2>
                <p>Looking at the repair map, we can see repair work is predominantly concentrated in built-up areas. This pattern, combined with some suggestions from SGN that they have no easily accessible data on quantities of gas leaking seem to raises some questions:</p>
                <ul>
                    <li>Is there a sampling bias? We have indication that SGN does not appear to measure leaks or be able to quantify them systematically. This suggests that the main mechanism of detection is from humans smelling gas, which the repair location data seems to corroborate. Almost all major repairs on gas leaks are happening in built-up areas.</li>
                    <li>Are Rural leaks being under-reported? The lack of human presence to detect gas by smell in these areas could mean a substantial number of leaks are going unnoticed and subsequently the true environmental impact from our gas network is likely severely underestimated. If rural leaks are indeed occurring unnoticed and unmeasured, the actual methane emissions could be much higher than current estimates suggest.</li>
                </ul>
                <p>This pattern might reflect differences in infrastructure age and maintenance between urban and rural areas, but it's difficult to confirm without more comprehensive detection methods.</p>
                <p>Given these observations, the 226 leaks which were reported over two years looks a lot like an undercount. The actual number, including undetected rural leaks, might be substantially higher.</p>
            </section>

            <section>
                <h2>Environmental Implications</h2>
                <p>So let's talk about the environmental implications. While exact figures on gas leakage volume are unavailable, the frequency of leaks is concerning:</p>
                <ol>
                    <li>Unburned methane is approximately 80 times more potent than CO2 as a greenhouse gas over a 20-year period. This is what is leaking from gas infrastructure.</li>
                    <li>With an average of 113 leaks per year in the RG8 area alone, the cumulative effect on greenhouse gas emissions could be substantial.</li>
                    <li>The lack of data on leak volumes and environmental impacts represents a significant blind spot in assessing the true climate cost of our gas infrastructure.</li>
                </ol>
                <p>Even small leakage rates can have outsized impacts. Self reported gas leakage rates sit around ~1%. Studies in other countries have found much higher leakage rates (e.g., some U.S. studies have found rates of 2.3% or higher). This potentially means that the leakage of unburned methane over a 20 year period is comparable to the combustion of the other 99% which is burned in boilers. Due to the potency of methane as a greenhouse gas, the error bars are extremely important. If leaks really do sit at 1%, the actual climate impact of gas heating would be not far off double what is widely reported at ~200gCO2 per kWh.</p>
            </section>

            <section>
                <h2>The Hydrogen Heating Fallacy</h2>
                <p>SGN, like other gas networks, seems to think they can simply swap natural gas for hydrogen in our existing infrastructure. They're proposing to use the same pipes, the same network, just with a different gas and few upgrades. But look at the repairs detailed above and ask yourself if this is realistic?</p>
                <p>The hydrogen heating proposal is highly questionable:</p>
                <ol>
                    <li>Hydrogen molecules are smaller than methane, guaranteeing even more leaks.</li>
                    <li>It extends methane's atmospheric lifetime, worsening global warming (hydrogen reacts with hydroxyl radicals in the atmosphere, which are the primary mechanism for breaking down methane. By consuming these radicals, hydrogen indirectly allows methane to persist longer. Studies suggest that hydrogen leaks could have 11 times the warming effect of CO2 over a 20-year period due to this indirect effect)</li>
                    <li>The entire proposal of heating homes with hydrogen is grossly inefficient compared to direct electrification.</li>
                </ol>
                <p>In short, hydrogen heating would exacerbate our current problems, not solve them. It's time to dismiss this idea and focus on real solutions like electrification.</p>
            </section>

            <section>
                <h2>EPC Shortcomings: A National Issue</h2>
                <p>So let's get to policy. The current Energy Performance Certificate (EPC) framework has a significant blind spot: it fails to account for distribution losses in the gas network. This oversight has far-reaching implications that extend well beyond our local findings.</p>
                <p>Consider this: in our small RG8 area alone, we uncovered 226 gas leaks over just two years. These leaks represent emissions that are completely unaccounted for in current EPC ratings. Now, let's scale this up. If our local leak rate is representative of the national picture, that's a staggering number of unaccounted emissions.</p>
                <p>What's even more alarming is the inconsistency in how different energy sources are treated. While gas distribution losses are ignored, the Standard Assessment Procedure (SAP) used for EPCs does account for losses in electricity distribution and also still treats electricity as if it's still largely generated by fossil fuels. This discrepancy creates a skewed comparison between gas and electric heating systems, unfairly penalising electricity while ignoring the hidden emissions in our gas network (evidenced above).</p>
                <p>The implications of this are profound. EPCs typically have a 10-year validity period. Over this time, the hidden emissions from these gas leaks could amount to a significant quantity of CO2 equivalent per household. Yet, homes continue to maintain apparently high efficiency ratings, creating a false sense of environmental performance.</p>
                <p>By ignoring these gas distribution losses while accounting for electrical losses and powerstation inefficiency, the EPC framework is inadvertently incentivising the continued use of gas heating systems. Homeowners and policymakers are making decisions based on incomplete and inconsistent information, potentially favouring gas systems over truly lower-emission alternatives. Without reviewing this urgently, our emissions reduction strategies for the housing sector will remain significantly off-target.</p>
                <p>In essence, we're working with a flawed and inconsistent metric. If we're serious about addressing climate change and accurately assessing our progress, we need to revise how we measure and rate household energy performance. The current EPC framework, by overlooking these 'invisible' gas emissions, is painting an overly optimistic picture of gas heating's environmental impact. This isn't just a local issue; it's a national problem that could be significantly hampering our efforts to combat climate change and unfairly disadvantage electric heating solutions which exist today.</p>
            </section>

            <section>
                <h2>Conclusions and Recommendations</h2>
                <p>These findings underscore the urgent need to address gas leaks and rapidly transition to electric heating solutions. Given the scale of unaccounted emissions and the inconsistencies in our current assessment frameworks, there's a few areas for improvement:</p>
                <ul>
                    <li>Implement comprehensive monitoring and assessment of gas leak volumes at both local and national levels. This data is crucial for understanding the true environmental impact of our gas infrastructure.</li>
                    <li>Dismiss hydrogen heating proposals outright. They represent a dangerous distraction that would exacerbate our current problems rather than solve them.</li>
                    <li>Urgently overhaul the EPC methodology to ensure consistent treatment of all energy sources. This must include accounting for gas leakages and losses. Only then can we provide accurate, unbiased information for sustainable home heating decisions.</li>
                    <li>Update energy policies to reflect the true climate impact of gas heating. This should include the previously unaccounted-for distribution losses and recognize the unfair advantage currently given to gas over electricity in our assessment methods.</li>
                </ul>
                <p>Thanks for reading!</p>
            </section>

            <section>
                <h2>Bonus Question</h2>
                <p>Have a guess which energy source is favoured by EPCs for hot water heating? Gas or a direct electric hot water cylinder?</p>
                <p><em>(Answer not provided in the original text)</em></p>
            </section>
        </Container>
    );
}

export default GasLeaksReport;