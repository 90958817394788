import React, { useState, useEffect, useRef } from 'react';

function Wind() {
  const [height, setHeight] = useState('100vh');
  const iframeRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (iframeRef.current) {
        setHeight(`${window.innerHeight}px`);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial height

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{ width: '100%', height: height, overflow: 'hidden' }}>
      <iframe
        ref={iframeRef}
        src="/wind_analysis.html"
        title="Wind Analysis"
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          overflow: 'hidden'
        }}
      />
    </div>
  );
}

export default Wind;