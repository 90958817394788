import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';


const Footer = () => {
  return (
    <footer className="mt-auto py-3 text-center" style={{ backgroundColor: 'white' }}>
      <Container>
        <Row>
          <Col className="text-center">
            <span>© 2024 Inorite Ltd - All rights reserved. Company No. 15762027</span>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
